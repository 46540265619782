import React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';

import * as styles from '../../styles/flex/feedback/contact.module.css';

function ContactForm() {
    return (
        <Layout>
            <Seo title="Contact Form" description="Contact us about an inquiry that you would like a response to on this page." />
            <section className={styles.ContactSection}>
                <div className={styles.Container}>
                    <h1>Contact Us</h1>
                    <p>
                        Fill out the form below to get in touch about an inquiry that you would like a 
                        response to. We will try to get back to you within 48 hours. If not, 
                        we will eventually. If its really important feel free to submit another 
                        form.
                    </p>
                    <form action="https://formsubmit.co/gtamaxprofit@gmail.com" method="POST">
                        <input type="hidden" name="_captcha" value="false"></input>
                        <input type="hidden" name="_subject" value="Contact Form Submission"></input>
                        <div className={styles.FormGroup}>
                            <label htmlFor="firstname">First Name*</label>
                            <input type="text" name="firstname" id="firstname" className={styles.FormElement} placeholder="First Name" required></input>
                        </div>
                        <div className={styles.FormGroup}>
                            <label htmlFor="lastname">Last Name*</label>
                            <input type="text" name="lastname" id="lastname" className={styles.FormElement} placeholder="Last Name" required></input>
                        </div>
                        <div className={styles.FormGroup}>
                            <label htmlFor="email">Email Address*</label>
                            <input type="text" name="email" id="email" className={styles.FormElement} placeholder="your@email.com" required></input>
                        </div>
                        <div className={styles.FormGroup}>
                            <label htmlFor="company">Company</label>
                            <input type="text" name="company" id="company" className={styles.FormElement} placeholder="Company Name"></input>
                        </div>
                        <div className={[styles.FormGroup, styles.Full].join(' ')}>
                            <label htmlFor="message">Whats on your mind?*</label>
                            <textarea name="message" id="message" className={styles.FormElement} placeholder="Write your message here..." required></textarea>
                        </div>
                        <div className={styles.SubmitGroup}>
                            <input type="submit" value="SEND MESSAGE"></input>
                        </div>
                        <input type="hidden" name="_next" value="https://www.gtamaxprofit.com"></input>
                    </form>
                </div>
                <div className={styles.TopTilt}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className={styles.ShapeFill}></path>
                    </svg>
                </div>
                <div className={styles.BottomTilt}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className={styles.ShapeFill}></path>
                    </svg>
                </div>
            </section>
        </Layout>
    )
}

export default ContactForm;